import { useRouter } from "next/router"
import Layout from "components/core/Layout"
import SEO from "components/core/SEO"
import { HeroSection } from "components/home/HeroSection"
import EmployeesSection from "components/home/EmployeesSection"
import { SecuritySection } from "components/home/SecuritySection"
import CfoSuiteSection from "components/home/CfoSuiteSection"
import GeneralCounselsSection from "components/home/GeneralCounselsSection"
import TradingEnforcementTrendsSection from "components/home/TradingEnforcementTrendsSection"
import TestimonialSection from "components/home/TestimonialSection"
import DemoSection from "components/home/DemoSection"
import EmployeeBenefitsSection from "components/home/EmployeeBenefitsSection"

const Landing = () => {
  const router = useRouter()

  const openContactModal = () => {
    router.push("/get-a-demo")
  }
  return (
    <Layout theme="black">
      <SEO
        title="Control the market impact of stock compensation"
        description="Employees represent 20% or more of retail shareholders. Candor equips public companies with powerful capital markets controls for employee stock."
        // socialcard="/images/home/social.jpg"
      />

      <HeroSection openContactModal={openContactModal} />
      <EmployeesSection />
      <CfoSuiteSection openContactModal={openContactModal} />
      <GeneralCounselsSection openContactModal={openContactModal} />
      <TradingEnforcementTrendsSection />
      <EmployeeBenefitsSection />
      <TestimonialSection />
      <SecuritySection />
      <DemoSection />
    </Layout>
  )
}

export default Landing
