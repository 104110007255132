import Script from "next/script"
import { useUser } from "@clerk/nextjs"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"

const FrontChatWidget = () => {
  const { user } = useUser()
  const isMobile = useIsMobile()

  const initializeFrontChat = async () => {
    let response = null

    const initParams: any = {
      chatId: "ecc418bee62fb9a789c4a5180d8a3bd8",
      useDefaultLauncher: true,
      email: user?.primaryEmailAddress?.emailAddress,
      name: user?.fullName,
    }

    if (isMobile) {
      initParams.welcomeMessageAppearance = "hidden"
    }

    try {
      // Authenticate the user's email
      response = await fetch("/api/front/get-hash", {
        method: "POST",
      })

      initParams.userHash = await response.text()
    } catch (e) {
      window?.Sentry?.captureException(e)
    }

    try {
      window.FrontChat("init", initParams)
    } catch (error) {
      window?.Sentry?.captureException("Could not initialize Front chat")
    }
  }

  return (
    <Script
      src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
      strategy="lazyOnload"
      onLoad={() => initializeFrontChat()}
    />
  )
}

export default FrontChatWidget
