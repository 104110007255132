import styles from "./Spinner.module.css"

export const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      className={styles.spinner}
    >
      <path
        d="M41.8415 27.0897C40.9565 30.4528 39.2099 33.527 36.774 36.009C34.3381 38.491 31.2971 40.2949 27.9512 41.2428C24.6052 42.1906 21.07 42.2497 17.6942 41.414C14.3185 40.5784 11.219 38.877 8.7016 36.4777C6.18421 34.0784 4.33592 31.0642 3.33915 27.7325C2.34237 24.4008 2.23156 20.8668 3.01765 17.4792C3.80373 14.0916 5.45955 10.9675 7.82168 8.41519C10.1838 5.86291 13.1706 3.97067 16.4874 2.92519"
        stroke="#141719"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </svg>
  )
}
